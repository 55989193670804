import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

//https://react-svgr.com/playground/
//https://material-ui.com/api/svg-icon/
function CardIcon(props) {
    return (
      <SvgIcon 
        viewBox="0 0 512 512"
        height={512}
        width={512}
        {...props}>
        
        <path d="M238.5,87L192,38.3c-5.5-5.8-13-9.1-21-9.3c-8-0.2-15.6,2.8-21.4,8.3L9.3,171.3c-5.8,5.5-9.1,13-9.3,21s2.8,15.6,8.3,21.4l90.7,95V147c0-33.1,26.9-60,60-60L238.5,87L238.5,87z" />
        <path d="M489.4,90.5l-188-47.7c-7.8-2-15.8-0.8-22.7,3.3s-11.8,10.6-13.7,18.4L259.2,87H353c33.1,0,60,26.9,60,60v298.5c3.2-0.7,6.3-1.9,9.1-3.6c6.9-4.1,11.8-10.6,13.7-18.4l75.2-296.6C515.1,110.9,505.4,94.5,489.4,90.5L489.4,90.5z" />
        <path d="M353,117H159c-16.5,0-30,13.5-30,30v306c0,16.5,13.5,30,30,30h194c16.5,0,30-13.5,30-30V147C383,130.5,369.5,117,353,117zM194,441L194,441c-14.9,0-27-12.1-27-27V186c0-14.9,12.1-27,27-27h0c14.9,0,27,12.1,27,27v228C221,428.9,208.9,441,194,441z" />

      </SvgIcon>
    );
  }

  export default CardIcon;